<template>
  <div :class="`${prefix}cornerstone`">
    <wl-cornerstone-banner
      active-tab="name"
      :title="cms.cornerstone.banner.title"
      :prefill-fields="$parent.$attrs['prefill-fields']"
      :search-tabs="cms.cornerstone.banner.search_tabs"
      :review-text="cms.cornerstone.banner.review_text"
      :secured-text="cms.cornerstone.banner.secured_text"
      :rating-image="ratingImage"
      :people-url="cms.cornerstone.banner.people_url"
      :default-state-option-label="
        cms.cornerstone.banner.default_state_option_label
      "
      :search-icon="cms.cornerstone.banner.search_icon"
      :no-query-string="true"
      landing-id="70k1"
      :fallback-fields="$parent.$attrs['fallback-fields']"
    />
    <what-records
      :callout="cms.page.lookup_service.callout"
      :title="cms.page.lookup_service.title"
      :image="cms.page.lookup_service.image"
      :mobile-image="cms.page.lookup_service.mobile_image"
      :copy="cms.page.lookup_service.copy"
    />
    <what-records
      class="section-color what-records--alt"
      :title="cms.page.who.title"
      :image="cms.page.who.image"
      :mobile-image="cms.page.who.mobile_image"
      :copy="cms.page.who.copy"
    />
    <what-data
      class="section-color-gradient"
      :title="cms.page.source_info.title"
      :copy="cms.page.source_info.copy"
      :items="cms.page.source_info.items"
      :cta-copy="cms.page.source_info.cta_copy"
      :button-text="cms.page.source_info.button_text"
      @what-data-btn-click="handleSearch"
    />
    <impact
      :title="cms.page.what_you_can.title"
      :copy="cms.page.what_you_can.copy"
      :list="cms.page.what_you_can.list"
    />
    <how-to
      :title="cms.page.find_someone.title"
      :copy="cms.page.find_someone.copy"
      :items="cms.page.find_someone.items"
      :button-text="cms.page.find_someone.button_text"
      @how-to-btn-click="handleSearch"
    />
    <ways-to-search
      :title="cms.page.ways_to_search.title"
      :copy="cms.page.ways_to_search.copy"
      :list="cms.page.ways_to_search.list"
    />
    <faq
      :title="cms.page.faq.title"
      :copy="cms.page.faq.copy"
      :items="cms.page.faq.items"
      :button-text="cms.page.faq.button_text"
      @faq-btn-click="handleSearch"
    />
    <testimonials
      :title="cms.testimonials.title"
      :sub-text="cms.testimonials.sub_text"
      :list="cms.testimonials.list"
    />
    <search-cta @search-cta-btn-click="handleCtaBtnClick" />
    <people-directory
      v-for="cardList in renderPeopleDirectory()"
      :key="`directory_${cardList}`"
      :card-list="cardList"
      @people-directory-btn-click="handleSearch"
    />
    <blog />
  </div>
</template>

<script>
// Components
import WlCornerstoneBanner from '@/components/shared/wl-cornerstone-banner';
import WhatRecords from '@/components/what-records.vue';
import WhatData from '@/components/what-data.vue';
import ImageTextSection from '@/components/image-text-section.vue';
import Impact from '@/components/impact.vue';
import SearchCta from '@/components/search-cta.vue';
import Testimonials from '@/components/testimonials.vue';
import PeopleDirectory from '@/sections/people-directory.vue';
import Blog from '@/components/blog.vue';
import Faq from '@/components/faq.vue';
import WaysToSearch from '@/components/ways-to-search.vue';
import HowTo from '@/components/how-to.vue';
// Helpers
import Meta from '@/assets/js/shared/misc/meta.mjs';
import getPeopleDirectoryData from '@/assets/js/shared/helpers/getPeopleDirectoryData';
// Images
import ratingImage from '@/assets/images/icons/rating.webp';
export default {
  name: 'home',
  components: {
    WaysToSearch,
    HowTo,
    Faq,
    Testimonials,
    PeopleDirectory,
    Blog,
    ImageTextSection,
    Impact,
    SearchCta,
    WhatRecords,
    WhatData,
    WlCornerstoneBanner
  },
  data() {
    return {
      ratingImage
    };
  },
  props: {
    cms: {
      type: Object,
      default: () => {
        return {
          page: require(`@/assets/cms/pages/home.json`),
          cornerstone: require(`@/assets/cms/pages/cornerstone.json`),
          global: require(`@/assets/cms/global.json`),
          testimonials: require(`@/assets/cms/components/testimonials.json`)
        };
      }
    }
  },
  computed: {
    prefix() {
      return this.$config.prefix || '';
    }
  },
  methods: {
    /**
     * Handles search button click
     */
    handleSearch() {
      // Open the header search if on mobile
      if (this.$parent.$parent.isMobile) {
        this.$parent.$parent.mobileSearchOpen();
      }

      // Added setTimeout in order to get the input field to focus
      setTimeout(() => {
        const firstNameField = document.querySelector(
          '.wl-header .tz-search input[name="firstName"]'
        );
        firstNameField.focus();
      }, 100);
    },
    renderPeopleDirectory() {
      return this.people && this.people.length > 0 ? [this.people] : [];
    },
    /**
     * Handles button click inside the search-cta component
     * @param btnIndex
     */
    handleCtaBtnClick(btnIndex) {
      if (btnIndex === 0) {
        this.handleSearch();
      }
    }
  },
  async asyncData({ $bugsnag }) {
    if (!process.server) {
      return;
    }

    const payload = await getPeopleDirectoryData($bugsnag);
    return payload;
  },
  head() {
    return {
      title: this.cms.page.page_title,
      meta: this.cms.page.meta ? Meta.convertToNuxtMeta(this.cms.page.meta) : []
    };
  }
};
</script>
