<template>
  <div class="wl-star-rating">
    <span
      v-for="totalRating in renderIf({ condition: hasTotalRating })"
      :key="totalRating"
      class="wl-star-rating__total"
      v-text="`${rating}/${total}`"
    />
    <div class="wl-star-rating__wrapper">
      <svg
        class="wl-star-rating__star"
        v-for="index in total"
        :key="index"
        width="20"
        height="20"
        viewBox="0 0 20 20"
      >
        <defs>
          <linearGradient id="halfStar">
            <stop offset="50%" :stop-color="color" />
            <stop offset="50%" stop-color="transparent" />
          </linearGradient>
        </defs>
        <path
          :fill="getStarColor(index)"
          :stroke="color"
          d="M10.0003 15.2167L4.12246 18.5069L5.43524 11.9L0.489746 7.32658L7.17895 6.53347L10.0003 0.416748L12.8217 6.53347L19.5108 7.32658L14.5654 11.9L15.8782 18.5069L10.0003 15.2167Z"
        />
      </svg>
    </div>
  </div>
</template>

<script>
import renderIf from '@/assets/js/shared/helpers/renderIf';

export default {
  name: 'wl-star-rating',
  props: {
    /**
     * The total number of stars to display
     */
    total: {
      type: Number,
      default: 5
    },
    /**
     * The rating to display
     * @example 4 or 4.5
     */
    rating: {
      type: Number,
      required: true
    },
    /**
     * Sets the fill and stroke color of the stars
     * @example #ce2c38, red, rgb(255, 0, 0)
     */
    color: {
      type: String,
      default: 'yellow'
    },
    /**
     * Show/hide the total rating text
     */
    hasTotalRating: {
      type: Boolean,
      default: true
    }
  },
  methods: {
    renderIf,
    getStarColor(index) {
      switch (true) {
        case index <= Math.floor(this.rating):
          return this.color;
        case index > Math.floor(this.rating) &&
          index - 1 < this.rating &&
          this.rating % 1 !== 0:
          return 'url(#halfStar)';
        default:
          return 'transparent';
      }
    }
  }
};
</script>
