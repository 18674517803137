<template>
  <div class="testimonials">
    <header
      v-for="header in renderIf({ condition: hasHeaderContent })"
      :key="header"
      class="testimonials__header"
    >
      <h2
        v-for="headerTitle in renderIf({ condition: title })"
        :key="headerTitle"
        class="testimonials__title"
        v-text="title"
      />
      <p
        v-for="headerSubtext in renderIf({ condition: subText })"
        :key="headerSubtext"
        class="testimonials__sub-text"
        v-text="subText"
      />
    </header>
    <vueper-slides
      class="no-shadow"
      ref="sliderRef"
      :gap="3"
      :infinite="false"
      :bulletsOutside="true"
      :arrowsOutside="false"
      :slideMultiple="true"
      :visible-slides="3"
      :breakpoints="{
        1199: { visibleSlides: 3 },
        991: { visibleSlides: 2 },
        800: { visibleSlides: 2 },
        450: { visibleSlides: 1 }
      }"
    >
      <template #arrow-left>
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none">
          <path
            d="M6.09935 8.24992H15.228V9.74992H6.09935L10.1223 13.7729L9.06168 14.8335L3.22803 8.99992L9.06168 3.16626L10.1223 4.22692L6.09935 8.24992Z"
            fill="#1F2D68"
          />
        </svg>
      </template>
      <template #arrow-right>
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none">
          <path
            d="M12.1287 8.24992L8.1057 4.22692L9.16635 3.16626L15 8.99992L9.16635 14.8335L8.1057 13.7729L12.1287 9.74992H3V8.24992H12.1287Z"
            fill="#1F2D68"
          />
        </svg>
      </template>
      <template #bullet="{ active, slideIndex, index }">
        <div
          class="testimonials__pagination"
          :class="{ 'testimonials__pagination--active': active }"
        />
      </template>
      <vueper-slide v-for="(item, i) in list" :key="i">
        <template #content>
          <div class="testimonial">
            <p class="testimonial__copy" v-text="item.content" />
            <div class="testimonial__footer">
              <div class="testimonial__author">
                <img
                  class="testimonial__img"
                  :src="item.image"
                  :alt="item.name"
                />
                <h3 class="testimonial__title" v-text="item.name" />
                <span class="testimonial__sub-text" v-text="item.job_title" />
              </div>
              <wl-star-rating :rating="item.rating" color="#CE2C38" />
            </div>
          </div>
        </template>
      </vueper-slide>
    </vueper-slides>
  </div>
</template>

<script>
// Components
import { VueperSlides, VueperSlide } from 'vueperslides';
import 'vueperslides/dist/vueperslides.css';
import WlStarRating from '@/components/shared/wl-star-rating';
// Helpers
import renderIf from '@/assets/js/shared/helpers/renderIf';
export default {
  name: 'testimonials',
  components: { WlStarRating, VueperSlides, VueperSlide },
  props: {
    title: {
      type: String
    },
    subText: {
      type: String
    },
    list: {
      type: Array
    }
  },
  data() {
    return {
      renderIf
    };
  },
  computed: {
    hasHeaderContent() {
      return !!this.title || !!this.subText;
    }
  }
};
</script>
