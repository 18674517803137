<template>
  <div class="search-cta">
    <div class="search-cta__wrapper">
      <h2 class="search-cta__title" v-html="title" />
      <tz-markdown class="search-cta__copy" :content="copy" />
      <div class="search-cta__btn-wrapper">
        <component
          v-for="(btn, index) in buttonList"
          :key="`btn_${index}`"
          :is="btn.link ? 'a' : 'button'"
          :href="btn.link ? btn.link : null"
          class="search-cta__btn"
          :class="btn.class_name"
          v-text="btn.text"
          @click="handleButtonClick(btn.link, index)"
        />
      </div>
    </div>
  </div>
</template>

<script>
// Components
import TzMarkdown from '@trazi/tz-markdown/src/tz-markdown.vue';
// CMS
import searchCtaCMS from 'assets/cms/components/search-cta.json';
export default {
  name: 'search-cta',
  components: {
    TzMarkdown
  },
  props: {
    /**
     * Title of the section
     */
    title: {
      type: String,
      default: searchCtaCMS.title
    },
    /**
     * Markdown copy
     */
    copy: {
      type: String,
      default: searchCtaCMS.copy
    },
    /**
     * Each button has the following properties:
     * @typedef {Object} Item
     * @property {string} text
     * @property {string} class_name - optional
     * @property {string} link - optional
     */
    buttonList: {
      type: Array,
      default: function() {
        return searchCtaCMS.button_list;
      },
      validator: function(value) {
        // Check if each object in the array has the required properties
        for (let i = 0; i < value.length; i++) {
          const button = value[i];

          if (!button.text) {
            console.error(
              `[Vue warn]: buttonList prop: "text" property is required`
            );

            return false;
          }
        }
        return true;
      }
    }
  },
  methods: {
    handleButtonClick(link, index) {
      if (!link) {
        this.$emit('search-cta-btn-click', index);
      }
    }
  }
};
</script>
