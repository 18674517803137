<template>
  <div class="img-text-section">
    <div class="img-text-section__wrapper">
      <div class="img-text-section__content">
        <h2 class="img-text-section__callout" v-text="callout" />
        <h3 class="img-text-section__title" v-text="title" />
        <tz-markdown class="img-text-section__copy" :content="copy" />
      </div>
      <div class="img-text-section__img-wrapper">
        <img
          class="img-text-section__img"
          :src="image"
          :alt="title"
          loading="lazy"
        />
      </div>
    </div>
  </div>
</template>

<script>
import TzMarkdown from '@trazi/tz-markdown/src/tz-markdown.vue';
import imageTextSectionCMS from '@/assets/cms/components/image-text-section.json';
export default {
  name: 'image-text-section',
  components: {
    TzMarkdown
  },
  props: {
    callout: {
      type: String,
      default: imageTextSectionCMS.callout
    },
    title: {
      type: String,
      default: imageTextSectionCMS.title
    },
    copy: {
      type: String,
      default: imageTextSectionCMS.copy
    },
    image: {
      type: String,
      default: imageTextSectionCMS.image
    }
  }
};
</script>
