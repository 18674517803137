<template>
  <section :class="`${prefix}people-directory`">
    <div :class="`${prefix}people-directory__wrapper`">
      <h2 :class="`${prefix}people-directory__title`" v-html="title" />
      <tz-markdown
        v-for="item in renderIf({ condition: copy })"
        :key="`copy_${item}`"
        :class="`${prefix}people-directory__copy`"
        :content="copy"
      />
      <h3
        :class="`${prefix}people-directory__alphabet-title`"
        v-text="alphabetTitle"
      />
      <ul :class="`${prefix}people-directory__alphabet`">
        <li
          v-for="letter in getAlphabetList()"
          :key="letter"
          :class="`${prefix}people-directory__letter`"
        >
          <a
            :href="
              getLetterIndexLink({ params: { letter: letter.toLowerCase() } })
            "
            :class="`${prefix}people-directory__letter-link`"
            v-text="letter"
          />
        </li>
      </ul>
      <div :class="`${prefix}people-directory__cards`">
        <a
          v-for="(card, index) in cardList"
          :key="`card_${index}`"
          :class="`${prefix}people-directory__card`"
          :href="
            formatIndexLink({
              letter: card.letter,
              primaryKey: card.primaryKey
            })
          "
        >
          <h3
            :class="`${prefix}people-directory__card-title`"
            v-text="card.range.replace('|', ' ')"
          />
          <p
            :class="`${prefix}people-directory__card-text`"
            v-html="`<span>Includes:</span> ${card.data.join(', ')}`"
          />
        </a>
      </div>
      <button
        :class="`${prefix}people-directory__btn`"
        v-text="buttonText"
        v-touch:tap="handleBtnClick"
      />
    </div>
  </section>
</template>

<script>
// Components
import TzMarkdown from '@trazi/tz-markdown/src/tz-markdown.vue';
// Helpers
import renderIf from '@/assets/js/shared/helpers/renderIf';
import getAlphabetList from '@/assets/js/shared/helpers/getAlphabetList';
import getGeneralLink from '@/assets/js/shared/helpers/getGeneralLink';
// CMS
import peopleDirectoryCMS from '@/assets/cms/components/people-directory.json';
export default {
  name: 'people-directory',
  components: {
    TzMarkdown
  },
  data() {
    return {
      renderIf,
      getAlphabetList
    };
  },
  computed: {
    prefix() {
      return this.$config.prefix || '';
    }
  },
  methods: {
    /**
     *
     * @param {object} opts
     * @param {string} opts.letter
     * @param {string} opts.primaryKey
     * @returns {string}
     */
    formatIndexLink(opts = {}) {
      const letter = opts.letter.toLowerCase();
      const tier2 = opts.primaryKey;

      return `/names-directory/${letter}/${tier2}/`;
    },
    handleBtnClick() {
      this.$emit('people-directory-btn-click');
    },
    /**
     * @param {object} opts
     * @param {object} opts.params
     * @returns {string}
     */
    getLetterIndexLink(opts = {}) {
      const href = getGeneralLink({
        router: this.$router,
        params: opts.params,
        routeType: 'bucket.letter'
      });

      return href;
    }
  },
  props: {
    /**
     * Title of the section
     */
    title: {
      type: String,
      default: peopleDirectoryCMS.title
    },
    /**
     * Markdown copy of the section
     */
    copy: {
      type: String,
      default: peopleDirectoryCMS.copy
    },
    /**
     * Button text
     */
    buttonText: {
      type: String,
      default: peopleDirectoryCMS.button_text
    },
    /**
     * Title for the alphabet list
     */
    alphabetTitle: {
      type: String,
      default: peopleDirectoryCMS.alphabet_title
    },
    /**
     * Each card has the following properties:
     * @typedef {Object} Card
     * @property {string[]} data
     * @property {string} letter
     * @property {string} primaryKey
     * @property {string} range
     * @property {string} secondaryKey
     */
    cardList: {
      type: Array
    }
  }
};
</script>
